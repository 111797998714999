<template>
  <div>
    <Page>
      <template slot="search">
        <el-form inline :model="params" ref="filtersForm">
          <el-form-item label="模版名称" prop="name">
            <el-input
              v-model="params.name"
              clearable
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template slot="otherBtn">
        <el-button
          v-auth="'add'"
          @click="
            visible = true
            currentItem = null
          "
        >
          新 增
        </el-button>
      </template>
      <div class="table-wrap">
        <el-table border :data="tableData" v-loading="loading">
          <el-table-column
            type="index"
            width="60"
            label="序号"
            align="center"
          ></el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            show-overflow-tooltip
            prop="name"
            label="模版名称"
          />
          <el-table-column
            header-align="center"
            align="center"
            show-overflow-tooltip
            prop="image"
            label="图片"
          >
            <template slot-scope="{ row }">
              <el-image
                v-viewer
                v-if="row.image"
                style="width: 80px; height: 80px"
                :src="interceptImg(row.image)"
              ></el-image>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            show-overflow-tooltip
            prop="description"
            label="故障描述"
          ></el-table-column>
          <el-table-column
            header-align="center"
            show-overflow-tooltip
            label="操作"
            width="180"
          >
            <template slot-scope="{ row }">
              <el-button
                size="medium"
                type="text"
                v-auth="'edit'"
                @click="
                  visible = true
                  currentItem = row
                "
              >
                编辑
              </el-button>
              <el-button
                size="medium"
                type="text"
                v-auth="'del'"
                @click="delProject(row)"
              >
                删除
              </el-button>
              <el-button
                size="medium"
                type="text"
                v-if="row.attachment"
                @click="$utils.openDownload(row.attachment)"
              >
                下载附件
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <Pagination
        :total="total"
        :page-size.sync="params.pageSize"
        :page.sync="params.pageNo"
        @change="getData"
      />
    </Page>
    <Addmodal
      @updateList="getData"
      :currentItem="currentItem"
      v-if="visible"
      :visible.sync="visible"
    />
  </div>
</template>

<script>
import watchParamsGetData from '../../mixins/watchParamsGetData'
import Addmodal from './components/Addmodal'
export default {
  components: { Addmodal },
  data() {
    return {
      visible: false
    }
  },
  mixins: [watchParamsGetData],
  methods: {
    async getData() {
      try {
        this.loading = true
        let params = { ...this.params }
        params.pageNum = params.pageNo
        params.pageNo = undefined
        const { list, total } = await this.$api.workOrder.getTemplateList(
          params
        )
        this.tableData = (list || []).map(item => {
          return { ...item }
        })
        this.total = total
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async delProject({ id = '' }) {
      try {
        await this.$confirm('确定删除模板吗？', '温馨提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.loading = true
        await this.$api.workOrder.delTemplate([id])
        this.getData()
        this.$message.success('删除成功')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  border-bottom: 1px solid #f6f6f6;
}
</style>
